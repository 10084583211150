import { IValues } from './types';

export const initialValues: IValues = {
  name: '',
  color: '#000',
  imageUrl: '',
  iconUrl: '',
  memberName: '',
  priority: 1,
  addonsDiscount: 0,
  planIds: [],
  spendings: {},
  recurringPrices: {},
  pointRewards: {},
  splitBaseRenewValues: {},
  isRegional: false,
  parentId: null,
};

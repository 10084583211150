import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	recurringFieldWrapper: {
		margin: 4
	},

  rewardsPointsWrapper: {
		border: `solid, 1px ${theme.palette.grey[400]}`,
		borderRadius: 5,
    margin: 10,
		padding: 10,

  },

  rewardsPointsTitle: {
		margin: '0 16px 0 0'
	}
}));

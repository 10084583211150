import { IFiltersFields } from '@root/components/GatewayList/types';
import { createQueryParams } from '@root/utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';

export interface IMethod {
  id: number;
  name: string;
  defaultProcessingFee: number;
  priority: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IGateway {
  id: number;
  rebillyId: string;
  name: string;
  currencies: string[];
  status: string;
  processingFee: number;
  exigoTransactionField: string;
  createdAt: Date;
  updatedAt: Date;
  gatewayMethod: IMethod;
  invoiceAbbr: string;
  companyName: string;
  invoiceAddress: string;
  healthCheckUrl: string;
  trnCode: string;
  isCrypto: boolean;
}

export interface IGatewayResponse {
  data: {
    items: IGateway[];
    meta: IMeta;
  };
}

export interface IUpdateGateway {
  processingFee: number;
  companyName: string;
}

export interface IGatewayListParams extends IPaginationParams, IFiltersFields {}

export const getGatewayList = (params: IPaginationParams) => {
  const query = createQueryParams(params);
  return apiClient
    .get<IGatewayResponse>(ApiUrls.gatewayList + `?${query}`)
    .then((response) => response);
};

export const updateGateway = (id: number, data: IUpdateGateway) => {
  return apiClient.patch(ApiUrls.editGateway + `${id}`, data).then((response) => response);
};

import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface ICreateGateway {
  processingFee: number;
  exigoTransactionField: string;
  invoiceAbbr: string;
  companyName: string;
  invoiceAddress: string;
  healthCheckUrl: string;
  trnCode: string;
  isCrypto: boolean;
}

const request = (data: ICreateGateway) =>
  apiClient.post<IResponseAxios>(ApiUrls.createGateway, data).then((response) => response);

export default request;

import { createQueryParams } from '@root/utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta, IPaginationParams } from '../types';
import { ICountry } from './getCountries';

export enum PAYMENT_METHOD_STATUSES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface IPaymentMethod {
  id: number;
  name: string;
  defaultProcessingFee: number;
  priority: number;
  createdAt: Date;
  updatedAt: Date;
  imageUrls: string[];
  countries: ICountry[];
  title: string;
  customFlow: boolean;
  status: PAYMENT_METHOD_STATUSES;
  showCountriesInMeta: boolean;
}

export interface IPaymentMethodResponse {
  data: {
    items: IPaymentMethod[];
    meta: IMeta;
  };
}

export interface IPaymentListParams extends IPaginationParams {
  status?: PAYMENT_METHOD_STATUSES | 'all';
}

export const getPaymentMethodList = (params: IPaymentListParams) => {
  const query = createQueryParams(params);
  return apiClient
    .get<IPaymentMethodResponse>(ApiUrls.paymentMethodList + `?${query}`)
    .then((response) => response);
};

export const updatePaymentMethod = (id: number, data: any) => {
  return apiClient.patch(ApiUrls.updatePaymentMethod + `${id}`, data).then((response) => response);
}

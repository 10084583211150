export enum PROGRAMMICAL_GLOBAL_SETTINGS {
  GLOBAL_PHONE_VERIFICATION_ENABLED = "GLOBAL_PHONE_VERIFICATION_ENABLED",
  GLOBAL_SIGN_AGREEMENT_ENABLED = "GLOBAL_SIGN_AGREEMENT_ENABLED",
  MAX_COUPON_DISCOUNT = "MAX_COUPON_DISCOUNT",
  MAX_LIMIT_OF_PHONE_NUMBER_USAGE = "MAX_LIMIT_OF_PHONE_NUMBER_USAGE",
  SUBTITLES_PRIORITY = "SUBTITLES_PRIORITY",
  INSTANCE_TYPES_IN_MAINTENANCE = "INSTANCE_TYPES_IN_MAINTENANCE",
  EMAIL_OTP_COUNTRY_EXCLUSION = "EMAIL_OTP_COUNTRY_EXCLUSION",
  MOBILE_OTP_COUNTRY_EXCLUSION = "MOBILE_OTP_COUNTRY_EXCLUSION",
}

import { RECURRING_TYPES } from '../../enums/recurring-type';
import { IPointRewardsInputs, IValues } from './types';

export const initialValues: IValues = {
  name: '',
  enabled: false,
  isVisible: false,
  isOneTime: false,
  exigoId: 0,
  oneDayRecurringSku: '',
  oneDayDeductSku: '',
  websiteUrl: '',
  mobileAppUrlIos: '',
  mobileAppUrlAndroid: '',
  imageUrl: null,
  bullets: [],
  isTechnical: false,
  isAgreementRequired: false,
  isExtended: false,
  // cv: 0,
  // qv: 0,
  // scv: 0,
  // sqv: 0,
  // fsb: 0,
  // rcv: 0,
  // rqv: 0,
  // rscv: 0,
  // matrixBV: 0,
  // rmatrixBV: 0,
  // rsqv: 0,
  // rfsb: 0,
  INITIAL: 0,
  RENEW: 0,
  reduceCoreRewards: false,
  skus: { [RECURRING_TYPES.ANNUAL]: '', [RECURRING_TYPES.MONTHLY]: '' },
  recurringSkus: { [RECURRING_TYPES.ANNUAL]: '', [RECURRING_TYPES.MONTHLY]: '' },
  addSageRoles: [],
  ghlTag: '',
  isHPA: false,
  recurringExtraPrice: null,
  pointRewards: {},
  agreementSignType: '',
};

export const pointRewards: IPointRewardsInputs[] = [
  { id: 'cv', size: 6, type: 'number' },
  { id: 'qv', size: 6, type: 'number' },
  { id: 'scv', size: 6, type: 'number' },
  { id: 'sqv', size: 6, type: 'number' },
  { id: 'fsb', size: 6, type: 'number' },
  { id: 'matrixBV', size: 6, type: 'number' },
];

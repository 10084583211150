import { SignAgreementType } from '@root/components/CreateEditService/types';
import { RECURRING_TYPES } from '@root/enums/recurring-type';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SERVICE, { IServiceItem } from '../../services/api/requests/services';
import { editServiceData } from '../../store/entities/actions';
import { selectServiceForEditing } from '../../store/entities/selectors';
import { IPointRewards } from './../../pages/CreateEditSwitchSubscriptionRule/types';
import { useServiceFormik } from './use-formik';

export const useServiceForm = () => {
  const dispatch = useDispatch();
  const serviceForEditing = useSelector(selectServiceForEditing);
  const [editService, setEditService] = useState<IServiceItem | null>(null);
  const [selectedRecurringTypes, setSelectedRecurringTypes] = useState<string[]>([]);
  const [isSeparateRenewal, setIsSeparateRenewal] = useState<boolean>(false);
  const [isOverwrite, setIsOverwrite] = useState<Record<string, boolean>>({});
  const fk = useServiceFormik(editService, { isSeparateRenewal, selectedRecurringTypes });
  const isEdit = !!editService?.id;
  const recurringFieldsVisible = !!fk.values?.exigoId;

  const agreementSignType = useMemo(
    () =>
      Object.values(SignAgreementType).map((item) => ({
        label: item,
        value: item,
      })),
    [SignAgreementType]
  );

  useEffect(() => {
    setIsOverwrite(
      selectedRecurringTypes.reduce(
        (acc, item) => {
          editService?.pointRewards ? (acc[item] = false) : (acc[item] = true);
          return acc;
        },
        {} as Record<string, boolean>
      )
    );
  }, [selectedRecurringTypes, editService?.pointRewards]);

  useEffect(() => {
    const fetchEditService = async () => {
      if (serviceForEditing?.id) {
        try {
          const response = await SERVICE.getServiceItem(serviceForEditing.id);

          setEditService(response.data.data);
        } catch (error) {
          console.log('error', error);
        }
      }
    };

    if (serviceForEditing?.id) {
      fetchEditService();
    } else {
      setEditService(serviceForEditing);
    }
    return () => {
      dispatch(editServiceData(null));
    };
  }, [serviceForEditing, dispatch]);

  useEffect(() => {
    const recurringTypes = [
      ...new Set([
        ...Object.keys(editService?.skus || {}),
        ...Object.keys(editService?.recurringSkus || {}),
      ]),
    ];
    setSelectedRecurringTypes(recurringTypes);
  }, [editService, setSelectedRecurringTypes]);

  useEffect(() => {
    const skus = Object.keys(fk.values.skus || {}).filter(
      (item) => !selectedRecurringTypes.includes(item)
    );
    const recurringSkus = Object.keys(fk.values.recurringSkus || {}).find(
      (item) => !selectedRecurringTypes.includes(item)
    );

    if (skus.length) {
      fk.setFieldValue('skus', {
        ...Object.keys(fk.values.skus || {})
          .filter((item) => selectedRecurringTypes.includes(item))
          .reduce(
            (acc, key) => {
              acc[key] = fk.values?.skus?.[key] as string;
              return acc;
            },
            {} as Record<string, string>
          ),
      });
    }

    if (recurringSkus?.length) {
      fk.setFieldValue('recurringSkus', {
        ...Object.keys(fk.values.recurringSkus || {})
          .filter((item) => selectedRecurringTypes.includes(item))
          .reduce(
            (acc, key) => {
              acc[key] = fk.values?.recurringSkus?.[key] as string;
              return acc;
            },
            {} as Record<string, string>
          ),
      });
    }
  }, [selectedRecurringTypes, fk.values.skus, fk.values.recurringSkus]);

  useEffect(() => {
    if (editService?.recurringExtraPrice) {
      setIsSeparateRenewal(!!editService?.recurringExtraPrice);
    }
  }, [editService?.recurringExtraPrice]);

  const handleTogglesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    fk.setFieldValue('isOneTime', checked);
    if (checked) {
      fk.setFieldValue('recurringSkus', {});
      fk.setFieldValue('skus', {});
    } else {
      fk.setFieldValue('skus', {});
      fk.setFieldValue('isHelix', false);
    }
  };

  const handleChangePointRewards = (e: ChangeEvent<HTMLInputElement>, item: string) => {
    const { name, value } = e.target;

    if (value) {
      fk.setFieldValue(`pointRewards[${item}][${name}]`, Number(value));
    } else {
      fk.setFieldValue(`pointRewards[${item}][${name}]`, undefined);


      const pointRewardItem = fk.values.pointRewards?.[item];
      const pointRewardName = pointRewardItem?.[name as keyof IPointRewards];
  
      if (pointRewardItem && pointRewardName && Object.keys(pointRewardName).length === 0) {
        fk.setFieldValue(`pointRewards[${item}]`, undefined);
      }
    }

  };

  const handleMultiply = useCallback(
    (recurringType: string, multiplicationValue: number) => {
      fk.setFieldValue(
        `recurringExtraPrice[${recurringType}]`,
        Number(fk.values.recurringExtraPrice?.[RECURRING_TYPES.MONTHLY]) * multiplicationValue
      );

      if (fk.values.pointRewards && fk.values.pointRewards.Monthly) {
        Object.entries(fk.values.pointRewards.Monthly).forEach(([pointName, pointValue]) => {
          fk.setFieldValue(
            `pointRewards[${recurringType}][${pointName}]`,
            pointValue * multiplicationValue
          );
        });
      }
    },
    [fk.values]
  );

  const state = {
    recurringFieldsVisible,
    isEdit,
    fk,
    selectedRecurringTypes,
    isSeparateRenewal,
    isOverwrite,
    agreementSignType,
  };

  const handlers = {
    setSelectedRecurringTypes,
    handleTogglesChange,
    setIsSeparateRenewal,
    handleChangePointRewards,
    setIsOverwrite,
    handleMultiply,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};

import { RECURRING_TYPES } from "@root/enums/recurring-type"

export const getAmountOfMonth = (recurringType: string): number | undefined => {
  if (recurringType === RECURRING_TYPES.MONTHLY) {
    return;
  }
  if (recurringType === RECURRING_TYPES['3X_MONTHLY']) {
    return 3;
  }
  if (recurringType === RECURRING_TYPES['6X_MONTHLY']) {
    return 6;
  }
  if (recurringType === RECURRING_TYPES.ANNUAL) {
    return 12;
  }
};

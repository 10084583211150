import {
  Button,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import CountrySelect from '../../components/CountrySelect';
import FileInput from '../../components/FileInput';
import { useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import { IMAGE_UPLOAD_ENTITY } from '../../constants';
import createPaymentMethod, {
  ICreatePaymentMethod,
} from '../../services/api/requests/createPaymentMethod';
import editPaymentMethod, {
  IEditPaymentMethod,
} from '../../services/api/requests/editPaymentMethod';
import { PAYMENT_METHOD_STATUSES } from '../../services/api/requests/getPaymentMethodList';
import { editPaymentMethodData } from '../../store/entities/actions';
import { selectPaymentMethodForEditing } from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  defaultProcessingFee: Yup.number().test('is-decimal', 'please use xx.xx format', (value) =>
    Boolean(String(value).match(/^([0-9]{0,2})((\.[0-9]{0,2})?)$/))
  ),
  priority: Yup.number().test('is-integer', 'please select integer from 0 to 99', (value) =>
    Boolean(String(value).match(/^([0-9]{0,2})$/))
  ),
});

interface ICreatePaymentMethodForm extends Omit<ICreatePaymentMethod, 'status'> {
  active: boolean;
}

const initialValues: ICreatePaymentMethodForm = {
  defaultProcessingFee: 0.0,
  priority: 1,
  imageUrls: [''],
  countries: [],
  title: '',
  customFlow: false,
  active: false,
  showCountriesInMeta: false,
};

const CreateEditPaymentMethod: FC = () => {
  const classes = useStandardFormStyles();
  const dispatch = useDispatch();
  const selectedPaymentMethod = useSelector(selectPaymentMethodForEditing);

  const action = selectedPaymentMethod ? 'Edit' : 'Create';

  useEffect((): any => {
    return () => {
      dispatch(editPaymentMethodData(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fk: FormikProps<ICreatePaymentMethodForm> = useFormik({
    initialValues: selectedPaymentMethod
      ? {
          defaultProcessingFee: selectedPaymentMethod.defaultProcessingFee,
          priority: selectedPaymentMethod.priority,
          imageUrls: selectedPaymentMethod.imageUrls || [''],
          countries: selectedPaymentMethod.countries?.map((c) => c.code) || [],
          title: selectedPaymentMethod.title,
          customFlow: selectedPaymentMethod.customFlow,
          active: selectedPaymentMethod.status === PAYMENT_METHOD_STATUSES.ACTIVE ? true : false,
          showCountriesInMeta: selectedPaymentMethod.showCountriesInMeta,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const createData: ICreatePaymentMethod = {
        defaultProcessingFee: values.defaultProcessingFee,
        priority: values.priority,
        imageUrls: values.imageUrls,
        countries: values.countries,
        title: values.title,
        customFlow: values.customFlow,
        status: values.active ? PAYMENT_METHOD_STATUSES.ACTIVE : PAYMENT_METHOD_STATUSES.INACTIVE,
        showCountriesInMeta: values.showCountriesInMeta,
      };

      if (selectedPaymentMethod) {
        const editData: IEditPaymentMethod = {
          ...createData,
          id: selectedPaymentMethod.id,
        };
        editPaymentMethod(editData).then((response) => {
          if (response) {
            setNotification('success', {
              message: 'Success',
            });

            dispatch(push(pageLinks.payments + '/1'));
          }
        });
        return;
      }

      createPaymentMethod(createData).then((response) => {
        if (response) {
          setNotification('success', {
            message: 'Success',
          });
          dispatch(push(pageLinks.payments + '/1'));
        }
      });
    },
  });

  console.warn('fg', fk.values.imageUrls);

  return (
    <Grid item xs={12}>
      <BackButton name="Back" link={pageLinks.payments} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{action} Payment Method</Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  autoComplete="Title"
                  name="title"
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  type="text"
                  label="Title"
                  autoFocus
                  error={!!(fk.errors.title && fk.touched.title)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.title && fk.errors.title}
                  value={fk.values.title}
                  onChange={fk.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete="Default Processing Fee"
                  name="defaultProcessingFee"
                  variant="outlined"
                  required
                  fullWidth
                  id="defaultProcessingFee"
                  type="number"
                  label="Default Processing Fee, %"
                  autoFocus
                  error={!!(fk.errors.defaultProcessingFee && fk.touched.defaultProcessingFee)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.defaultProcessingFee && fk.errors.defaultProcessingFee}
                  value={fk.values.defaultProcessingFee}
                  onChange={fk.handleChange}
                  inputProps={{ step: 0.01 }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="priority"
                  variant="outlined"
                  required
                  fullWidth
                  id="priority"
                  type="number"
                  label="UI priority order, number"
                  error={!!(fk.errors.priority && fk.touched.priority)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.priority && fk.errors.priority}
                  value={fk.values.priority}
                  onChange={fk.handleChange}
                  inputProps={{ step: 1 }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      id='customFlow'
                      checked={fk.values.customFlow ?? false}
                      onChange={fk.handleChange}
                      name="customFlow"
                      color="primary"
                    />
                  }
                  label="Custom Payment Flow"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      id={'active'}
                      checked={fk.values?.active}
                      onChange={(e) => {
                        fk.handleChange(e);
                      }}
                      name="active"
                      color="primary"
                    />
                  }
                  label="Active"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      id="showCountriesInMeta"
                      checked={fk.values.showCountriesInMeta ?? false}
                      onChange={(e) => {
                        fk.handleChange(e);
                      }}
                      name="showCountriesInMeta"
                      color="primary"
                    />
                  }
                  label="Show Countries In Meta"
                />
              </Grid>

              {fk.values.imageUrls.map((item, index) => (
                <Grid item xs={12}>
                  <FileInput
                    id={`imageUrls.[${index}]`}
                    label="Payment method image"
                    imageUrl={fk.values.imageUrls[index]}
                    imageUrlField={`imageUrls.[${index}]`}
                    fk={fk}
                    entity={IMAGE_UPLOAD_ENTITY.PAYMENT_METHOD_IMAGE}
                  />
                </Grid>
              ))}

              {fk.values.imageUrls.every((item) => item !== '') && (
                <Box display="flex" alignItems={'center'}>
                  <Button
                    onClick={() =>
                      fk.setFieldValue(`imageUrls.[${fk.values.imageUrls.length}]`, '')
                    }
                  >
                    <AddIcon />
                    Add one more image
                  </Button>
                </Box>
              )}

              <Grid item xs={12}>
                <CountrySelect
                  id="countries"
                  value={fk.values.countries}
                  onChange={fk.setFieldValue}
                  error={!!(fk.errors.countries && fk.touched.countries)}
                  onBlur={fk.handleBlur}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {action}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditPaymentMethod;

import { SignAgreementType } from "@root/components/CreateEditService/types";
import { createQueryParams } from '../../../utils/helpers';
import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IMeta } from '../types';
import { IGetOrdersParams } from './orders';

export interface IPointRewards {
  cv: number;
  qv: number;
  scv: number;
  sqv: number;
  fsb: number;
  matrixBV: number;
  rcv: number;
  rqv: number;
  rscv: number;
  rsqv: number;
  rfsb: number;
  rmatrixBV: number;
}

interface IQuestRewards {
  INITIAL: number;
  RENEW: number;
}

interface IService {
  name: string;
  enabled: boolean;
  isVisible: boolean;
  isOneTime: boolean;
  exigoId?: number;
  oneDayRecurringSku?: string;
  imageUrl: string | null;
  oneDayDeductSku?: string;
  websiteUrl?: string;
  mobileAppUrlAndroid?: string;
  mobileAppUrlIos?: string;
  bullets: string[];
  isTechnical: boolean;
  isAgreementRequired: boolean;
  createdAt?: string;
  updatedAt?: string;
  pointRewards: Record<string, IPointRewards> | null;
  questRewards: IQuestRewards;
  reduceCoreRewards: boolean;
  recurringSkus?: Record<string, string>;
  skus?: Record<string, string>;
  addSageRoles?: [];
  ghlTag?: string | null;
  isExtended: boolean;
  isHPA: boolean;
  recurringExtraPrice: Record<string, string | undefined> | null;
  agreementSignType: SignAgreementType | '';
}

export interface IServiceData extends IService {}

export interface IServiceItem extends IService {
  id: number | null;
}

export interface IServiceItemList {
  data: {
    items: IServiceItem[];
    meta: IMeta;
  };
}

const getList = (params: any) => {
  const { recurringTypes, ...rest } = params;
  let query = createQueryParams<IGetOrdersParams>(rest);
  if (recurringTypes.length) {
    query += `&recurringTypes=${recurringTypes.join(',')}`;
  }

  return apiClient.get<IServiceItemList>(ApiUrls.getServiceList + `?${query}`);
};

const getUpsellingRulesServices = () => {
  return apiClient.get<IServiceItem[]>(ApiUrls.upsellingRulesServices);
};

const createService = (data: IServiceData) => {
  return apiClient.post<IServiceItem>(ApiUrls.createService, data);
};

const getServiceItem = (id: number) => {
  return apiClient.get<{ data: IServiceItem }>(ApiUrls.getService.replace('{id}', `${id}`));
};

const updateService = (id: number, data: IServiceData) => {
  return apiClient.put<IServiceItem>(ApiUrls.updateServiceValue.replace('{id}', `${id}`), data);
};

const deleteService = (id: number) => {
  return apiClient.delete<IServiceItem>(ApiUrls.deleteService.replace('{id}', `${id}`));
};

const SERVICE = {
  getList,
  createService,
  getServiceItem,
  updateService,
  deleteService,
  getUpsellingRulesServices,
};

export default SERVICE;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import membershipLevelRequests from '../../services/api/requests/membershipLevels';
import { selectServiceForEditing } from '../../store/entities/selectors';
import { setSuccessNotification } from '../../utils/notifications';
import { emptySettings } from './static-data';
import { IValues, RegionalMembershipLevelDto } from './types';
import { useFormikHandler } from './use-formik';

export const useForm = (membershipId: number, parentId: number | null) => {
  const dispatch = useDispatch();
  const serviceForEditing = useSelector(selectServiceForEditing);
  const [membershipLevelRegionalSettings, setMembershipLevelRegionalSettings] = useState<
    IValues['regionalSettings'] | undefined
  >();

  const fk = useFormikHandler(membershipId, membershipLevelRegionalSettings);
  const isEdit = true;

  const [recurringTypes, setSelectedRecurringTypes] = React.useState<Array<string[]>>([[]]);

  useEffect(() => {
    const fetchData = async () => {
      if (membershipId) {
        try {
          const response = await membershipLevelRequests.getMembershipLevelRegionalSettings(
            parentId || membershipId
          );
          if (response?.data?.data.length) {
            setMembershipLevelRegionalSettings(response?.data?.data);
            setSelectedRecurringTypes(
              response?.data?.data.map((item) => Object.keys(item.spendings))
            );
          }
        } catch (error) {
          console.log('error', error);
        }
      }
    };

    fetchData();

    return () => {
      setMembershipLevelRegionalSettings(undefined);
    };
  }, [serviceForEditing, dispatch, membershipId]);

  const enabledPeriodSettings = useCallback(
    (index, key, presents) => {
      if (presents) {
        fk.setFieldValue(`regionalSettings.${index}.${key}`, undefined);
      } else {
        fk.setFieldValue(
          `regionalSettings.[${index}].${key}`,
          emptySettings[key as keyof typeof emptySettings]
        );
      }
    },
    [fk]
  );

  const handleSubmit = useCallback(
    async (data, index) => {
      try {
        if (data?.id) {
          await membershipLevelRequests.editMembershipLevelRegionalSetting(
            membershipId,
            data?.id,
            data as any,
            parentId ? parentId?.toString() : ''
          );
        } else {
          const response = await membershipLevelRequests.createMembershipLevelRegionalSettings(
            membershipId,
            data as any
          );

          fk.setFieldValue(`regionalSettings.${index}.id`, response.data.data.id);
        }
        setSuccessNotification();
      } catch (error) {
        console.log('error', error);
      }
    },
    [membershipId, fk]
  );

  const handleDelete = useCallback(
    async (id) => {
      try {
        await membershipLevelRequests.deleteMembershipLevelRegionalSetting(membershipId, id);

        fk.setValues((prev) => ({
          ...prev,
          regionalSettings: prev.regionalSettings.filter((item) => item.id !== id),
        }));

        setSuccessNotification();
      } catch (error) {
        console.log('error', error);
      }
    },
    [membershipId, fk]
  );

  const handleSetRecurringTypes = (index: number) => (value: string[]) => {
    const newRecurringTypes = [...recurringTypes];
    newRecurringTypes[index] = value;
    const addedValue = value.filter((item) => !recurringTypes[index].includes(item));
    const deletedValue = recurringTypes[index].filter((item) => !value.includes(item));

    // add action
    if (addedValue.length) {
      const recurringType = addedValue[0];
      const { spendings, recurringPrices, splitBaseRenewValues, pointRewards, countryCodes, id } =
        fk.values.regionalSettings[index];

      const setting = {
        id,
        spendings: {
          ...spendings,
          [recurringType]: {
            min: '',
            max: '',
          },
        },
        recurringPrices: {
          ...recurringPrices,
          [recurringType]: '',
        },
        pointRewards: {
          ...pointRewards,
          [recurringType]: {
            rcv: '',
            rqv: '',
            rscv: '',
            rsqv: '',
            rfsb: '',
            rmatrixBV: '',
          },
        },
        splitBaseRenewValues: {
          ...splitBaseRenewValues,
          [recurringType]: '',
        },
        countryCodes,
      };
      fk.values.regionalSettings[index] = setting as RegionalMembershipLevelDto;
    }

    if (deletedValue.length) {
      const recurringType = deletedValue[0];
      const { spendings, recurringPrices, splitBaseRenewValues, pointRewards, countryCodes, id } =
        fk.values.regionalSettings[index];

      const setting = {
        id,
        spendings: {
          ...spendings,
          [recurringType]: undefined,
        },
        recurringPrices: {
          ...recurringPrices,
          [recurringType]: undefined,
        },
        pointRewards: {
          ...pointRewards,
          [recurringType]: undefined,
        },
        splitBaseRenewValues: {
          ...splitBaseRenewValues,
          [recurringType]: undefined,
        },
        countryCodes,
      };

      fk.values.regionalSettings[index] = setting as RegionalMembershipLevelDto;
    }

    setSelectedRecurringTypes(newRecurringTypes);
  };

  const state = {
    isEdit,
    fk,
    recurringTypes,
  };

  const handlers = {
    setSelectedRecurringTypes,
    enabledPeriodSettings,
    handleSubmit,
    handleDelete,
    handleSetRecurringTypes,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
